import '../../styles/CaseStudy.css';
import '../../styles/Typography.css';
import '../../styles/Tag.css';
import '../../styles/Global.css';

function Ericsson() {
    const imageTechnologyUrls = [
        'images/technologies/outsystems.png',
    ];

    return (
        <div className={'nimber-study-block'}>
            <div>
                <div>
                    <h3 className={'nimber-tag'}>CASE STUDY</h3>
                    <h1 className={'nimber-main-title nimber-foundation-neutral-500'}>Manage the purchasing and supply of services related to the operation of Ericsson</h1>
                    <p className={'nimber-secondary-text nimber-foundation-neutral-500'}>Development of a software for company project and resource management, through project planning and monitoring. The project was developed using a 4 Layer canvas architecture with an internal database and several integrations with other systems to obtain data related to materials and their values and for communication with suppliers.</p>
                </div>
                <div>
                    <h3 className={'nimber-t-study-info'}>Client</h3>
                    <img src={'images/clients/normal/Ericsson.png'} alt={'Ericsson logo'} className={'nimber-g-margin-top-16 nimber-g-margin-bottom-40'} />
                    <h3 className={'nimber-t-study-info'}>Main Technologies</h3>
                    <div className={'nimber-case-techs nimber-g-margin-top-16'}>
                        {imageTechnologyUrls.map((imageUrl, index) => (
                            <div>
                                <img key={index} src={imageUrl} alt={`a ${index}`} className="gallery-image" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ericsson