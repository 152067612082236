import '../styles/Footer.css'
import '../styles/Typography.css'
import '../styles/Button.css'
import React, { useState, useEffect } from 'react';

function FixedFooter() {
    const [showFooter, setShowFooter] = useState(window.innerWidth <= 768);
    const [headHeight, setHeadHeight] = useState(0);

    useEffect(() => {
        // Calculate the height of the Head component
        const headElement = document.getElementById('head-component');
        if (headElement) {
            setHeadHeight(headElement.clientHeight);
        }

        // Add a scroll event listener to handle scroll position
        const handleScroll = () => {
            const scrollY = window.scrollY || window.pageYOffset;
            setShowFooter(scrollY > headHeight);
        };

        // Apply the scroll event listener only for non-mobile devices
        if (window.innerWidth > 768) {
            window.addEventListener('scroll', handleScroll);
        }

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headHeight]);

    const scrollToTop = () => {
        const targetDiv = document.getElementById('form');
        if (targetDiv) {
            targetDiv.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return showFooter ? (
        <div className="fixed-footer-container">
            <div className={'nimber-max fixed-footer'}>
                <h2 className={'nimber-t-footer'}>Let's work together</h2>
                <button type={'button'} className={'nimber-button'} onClick={scrollToTop}>
                    SET UP A MEETING
                    <img src={'images/icons/Paper_Plane.png'} alt={'Paper Plane'} />
                </button>
            </div>
        </div>
    ) : null;
}

export default FixedFooter;