import '../styles/Talent.css';
import '../styles/Global.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Pagination from "./Pagination";
import React, { useState, useRef } from "react";
import Access from "./TalentPages/Access";
import Optimizing from "./TalentPages/Optimizing";
import Legal from "./TalentPages/Legal";
import Talented from "./TalentPages/Talented";
import Welcoming from "./TalentPages/Welcoming";
import { NextArrow, PrevArrow } from "./Arrows";

function Talent() {
    const [currentPage, setCurrentPage] = useState(0);
    const [isSliderHovered, setIsSliderHovered] = useState(false);
    const sliderRef = useRef(null);

    const components = [Optimizing, Access, Welcoming, Talented, Legal];

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        afterChange: current => setCurrentPage(current),
        nextArrow: isSliderHovered && <NextArrow />,
        prevArrow: isSliderHovered && <PrevArrow />,
    };

    const changePage = page => {
        setCurrentPage(page);
        sliderRef.current.slickGoTo(page);
    };

    return (
        <div className={'nimber-flex-center nimber-talent-block'}>
            <div
                className={'nimber-max'}
                onMouseEnter={() => setIsSliderHovered(true)}
                onMouseLeave={() => setIsSliderHovered(false)}
            >
                <Slider ref={sliderRef} {...settings}>
                    {components.map((Component, index) => (
                        <div key={index}>
                            <Component />
                        </div>
                    ))}
                </Slider>
                <Pagination totalPages={components.length} onPageChange={changePage} currentPage={currentPage + 1}></Pagination>
            </div>
        </div>
    )
}

export default Talent;