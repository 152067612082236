import './App.css';
import Head from "./components/Head";
import OurClients from "./components/OurClients";
import Talent from "./components/Talent";
import GrowingTogether from "./components/GrowingTogether";
import CaseStudy from "./components/CaseStudy";
import Footer from "./components/Footer";
import FixedFooter from "./components/FixedFooter";
import Cookies from "./components/Cookies";


function App() {
    return (
        <div className="App">
            <Head></Head>
            <OurClients></OurClients>
            <Talent></Talent>
            <GrowingTogether></GrowingTogether>
            <CaseStudy></CaseStudy>
            <Footer></Footer>
            <Cookies />
            <FixedFooter></FixedFooter>
        </div>
    );
}

export default App;
