import React from "react";
import '../styles/Arrows.css'

const NextArrow = ({ onClick }) => {
    return <div className="slick-next right-arrow" onClick={onClick}><img src={'images/Chevron_Right.png'} alt={'arrow right'}/></div>;
};

const PrevArrow = ({ onClick }) => {
    return <div className="slick-prev left-arrow" onClick={onClick}><img src={'images/Chevron_Left.png'} alt={'arrow left'}/></div>;
};

export { NextArrow, PrevArrow };