import '../styles/Pagination.css'

const Pagination = ({ totalPages, onPageChange, currentPage}) => {
    const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

    const handlePageClick = page => {
        onPageChange(page - 1);
    };

    return (
        <div className={'pagination'}>
            {pages.map(page => (
                <div
                    onClick={() => handlePageClick(page)}
                    key={'' + page}
                    className={`pagination-circle ${currentPage === page ? 'selected' : ''}`}
                />
            ))}
        </div>
    )
}

export default Pagination