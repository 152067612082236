import '../styles/Tag.css'
import '../styles/Typography.css'
import '../styles/GrowingTogether.css'
import '../styles/Global.css'

function GrowingTogether() {
    return (
        <div className={'nimber-growing-wrapper'}>
            <div className={'nimber-max '}>
                <div className={'nimber-tag-holder'}>
                    <h3 className={'nimber-tag'}>GROWING TOGETHER</h3>
                </div>
                <h1 className={'nimber-main-title'}>With our talented team, we achieve long-lasting impact with nimble solutions.</h1>
                <div className={'nimber-growing-three-block'}>
                    <div className={'nimber-growing-single-block'}>
                        <img src={'images/growing/TalentCost.png'} alt={'Talent Cost'}/>
                        <p className={'nimber-t-study-info nimber-g-margin-top-16'}>Talent Cost Ratio</p>
                    </div>
                    <div className={'nimber-growing-single-block'}>
                        <img src={'images/growing/Location.png'} alt={'Location'}/>
                        <p className={'nimber-t-study-info nimber-g-margin-top-16'}>Prime Location</p>
                    </div>
                    <div className={'nimber-growing-single-block'}>
                        <img src={'images/growing/Culture.png'} alt={'Culture'}/>
                        <p className={'nimber-t-study-info nimber-g-margin-top-16'}>Culture and Diversity</p>
                    </div>
                </div>
                <div className={'nimber-growing-three-block nimber-g-margin-top-32'}>
                    <div className={'nimber-growing-single-block'}>
                        <img src={'images/growing/Players.png'} alt={'Players'}/>
                        <p className={'nimber-t-study-info nimber-g-margin-top-16'}>Nimber Players</p>
                    </div>
                    <div className={'nimber-growing-single-block'}>
                        <img src={'images/growing/Legal.png'} alt={'Legal'}/>
                        <p className={'nimber-t-study-info nimber-g-margin-top-16'}>Legal and Focus</p>
                    </div>
                    <div className={'nimber-growing-single-block'}>
                        <img src={'images/growing/Clients.png'} alt={'Clients'}/>
                        <p className={'nimber-t-study-info nimber-g-margin-top-16'}>+20 Clients</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GrowingTogether