import '../../styles/Talent.css';
import '../../styles/Typography.css';
import '../../styles/Tag.css';

function Access () {
    return(
        <div className={'nimber-talent-block'}>
            <div className={'nimber-talent-left'}>
                <h3 className={'nimber-tag'}>THRIVING IN DIVERSITY, CULTURE AND TALENT</h3>
                <h1 className={'nimber-main-title nimber-foundation-neutral-500'}>Access to overseas markets</h1>
                <p className={'nimber-secondary-text nimber-foundation-neutral-500'}>Is not just about our pleasant weather! Portugal's strategic location on the Iberian Peninsula provides easy access to both European and African markets. This makes it a great location for companies looking to expand their operations and reach new customers.</p>
            </div>
            <div className={'nimber-talent-img'}>
                <img src={'images/talent/Access.png'} alt={'Access'} />
            </div>
        </div>
    )
}

export default Access