import '../styles/Head.css';
import '../styles/Typography.css';
import '../styles/Tag.css'
import '../styles/Form.css'
import '../styles/Button.css'
import '../styles/Global.css'
import '../styles/Sent.css'
import { useState } from "react";

function Head() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        companyName: '',
        agreeToTerms: false,
    });
    const [emailSent, setEmailSent] = useState(false);

    const handleInputChange = event => {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormData(prevState => ({
            ...prevState,
            [name]: newValue,
        }));
    };

    const handleTermsLinkClick = () => {
        // Logic to download the terms file when the link is clicked
        // You can use methods like fetch or create a hidden anchor element to trigger the download
        // For example, using fetch:
        fetch('/files/policies.pdf')
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'terms.pdf';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://leadgen.nimber.pt/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setEmailSent(true);
                console.log('Email sent successfully');
                // Reset the form or show a success message to the user
            } else {
                console.error('Failed to send email');
                // Handle error, show an error message to the user, etc.
            }
        } catch (error) {
            console.error('Error sending email:', error);
            // Handle error, show an error message to the user, etc.
        }
    };

    return (
        <div className={'nimber-head'} id={'head-component'}>
            <div className={'nimber-max nimber-head-sub'}>
                <div className={'nimber-head-left'}>
                    <img src={'images/Logo.png'} alt={'Nimber Logo'}/>
                    <h3 className={'nimber-tag'}>WE PROVIDE THE BEST SERVICES FOR BUSINESSES</h3>
                    <h1 className={'nimber-main-title nimber-foundation-neutral-500'}>At Nimber we take<br></br>
                        care of all your<br></br>
                        <span className={'nimber-primary-primary-500'}>IT challenges.</span></h1>
                    <p className={'nimber-secondary-text nimber-foundation-neutral-500'}>We are a team of dedicated and skilled professionals, driven by our passion for delivering exceptional results. Let’s work together to achieve your goals and take your business to the next level.</p>
                </div>
                <div>
                    {emailSent ? (
                        <div className={'sent-wrapper'}>
                            <h2 className={'sent-top-text'}>Thank you for reaching out to us!</h2>
                            <h3 className={'sent-middle-text'}>Your message is important and will be handled with the utmost care.</h3>
                            <p className={'sent-bottom-text'}>You will receive a response shortly. Have a great day!</p>
                            <img src={'images/Check_Big.png'} alt={'Check'}/>
                        </div>
                    ) : (
                        <div className={'form-wrapper'} id={'form'}>
                            <h2 className={'nimber-clean form-head-text'}>Let's Work Together</h2>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group nimber-g-margin-top-24">
                                    <label htmlFor="name">Name*</label>
                                    <input
                                        className={'form-group-input'}
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        placeholder={'Enter your name'}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email*</label>
                                    <input
                                        className={'form-group-input'}
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        placeholder={'Enter your email'}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phoneNumber">Phone Number*</label>
                                    <input
                                        className={'form-group-input'}
                                        type="tel"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        value={formData.phoneNumber}
                                        onChange={handleInputChange}
                                        placeholder={'(750) 932-2345'}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="companyName">Company Name*</label>
                                    <input
                                        className={'form-group-input'}
                                        type="text"
                                        id="companyName"
                                        name="companyName"
                                        value={formData.companyName}
                                        onChange={handleInputChange}
                                        placeholder={'Enter your Company Name'}
                                        required
                                    />
                                </div>
                                <div className="form-group" style={{ display: "flex", alignItems: "center"}}>
                                    <label className={'form-checkbox-label'}>
                                        <input
                                            className={'form-group-checkbox'}
                                            type="checkbox"
                                            name="agreeToTerms"
                                            checked={formData.agreeToTerms}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </label>
                                    <span className={'checkbox-text'}>I agree with Nimber’s <span onClick={handleTermsLinkClick} className="terms-link">Terms, Privacy Policy</span></span>
                                </div>
                                <button type="submit" className={'nimber-button nimber-g-width-100'}>SET UP A MEETING<img src={'images/icons/Paper_Plane.png'} alt={'Paper Plane'} /></button>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Head;