import '../styles/OurClients.css'
import '../styles/Typography.css'
import '../styles/Global.css'
import React, { useState, useEffect } from 'react';

function OurClients() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={'nimber-client-wrapper'}>
            <div className={'nimber-max nimber-client-sub'}>
                <p className={'nimber-t-study-info'}>Our clients</p>
                <div><img src={'images/clients/grey/Galp.png'} alt={'Galp'}/></div>
                {windowWidth > 568 &&<div><img src={'images/clients/grey/SIBS.png'} alt={'SIBS'}/></div>}
                {windowWidth > 768 && <div><img src={'images/clients/grey/Cofidis.png'} alt={'Cofidis'}/></div>}
                {windowWidth > 1024 && <div><img src={'images/clients/grey/Anticimex.png'} alt={'Anticimex'}/></div>}
                {windowWidth > 1280 && <div><img src={'images/clients/grey/Nos.png'} alt={'Nos'}/></div>}
            </div>
        </div>
    );
}
export default OurClients;