import '../../styles/Talent.css';
import '../../styles/Typography.css';
import '../../styles/Tag.css';

function Legal () {
    return(
        <div className={'nimber-talent-block'}>
            <div className={'nimber-talent-left'}>
                <h3 className={'nimber-tag'}>THRIVING IN DIVERSITY, CULTURE AND TALENT</h3>
                <h1 className={'nimber-main-title nimber-foundation-neutral-500'}>Legal and focused<br></br>teams</h1>
                <p className={'nimber-secondary-text nimber-foundation-neutral-500'}>Portugal has a stable and predictable legal system that encourages foreign investment and business growth. We concentrate solely on your core business and take care of all the labor and legal issues. Let the game happen!</p>
            </div>
            <div className={'nimber-talent-img'}>
                <img src={'images/talent/Legal.png'} alt={'Legal'} />
            </div>
        </div>
    )
}

export default Legal