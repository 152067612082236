import '../../styles/Talent.css';
import '../../styles/Typography.css';
import '../../styles/Tag.css';

function Welcoming () {
    return(
        <div className={'nimber-talent-block'}>
            <div className={'nimber-talent-left'}>
                <h3 className={'nimber-tag'}>THRIVING IN DIVERSITY, CULTURE AND TALENT</h3>
                <h1 className={'nimber-main-title nimber-foundation-neutral-500'}>A welcoming<br></br>culture and an<br></br>open mind</h1>
                <p className={'nimber-secondary-text nimber-foundation-neutral-500'}>People with a history of cross-border dialogue and a vibrant cosmopolitan culture. This diversity offers a unique perspective to team dynamics, enabling them to work effectively with people from various backgrounds and cultures. Teams may profit from this by viewing their job from a wider viewpoint, which will improve their capacity for innovation, creativity, and problem-solving.</p>
            </div>
            <div className={'nimber-talent-img'}>
                <img src={'images/talent/Welcoming.png'} alt={'Welcoming'} />
            </div>
        </div>
    )
}

export default Welcoming