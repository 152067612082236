import '../styles/CaseStudy.css';
import '../styles/Typography.css';
import '../styles/Tag.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Pagination from "./Pagination";
import {useRef, useState} from "react";
import Anticimex from "./CaseStudyPages/Anticimex";
import Galp from "./CaseStudyPages/Galp";
import Nos from "./CaseStudyPages/Nos";
import Ericsson from "./CaseStudyPages/Ericsson";
import { NextArrow, PrevArrow } from "./Arrows";


function CaseStudy() {
    const [currentPage, setCurrentPage] = useState(0);
    const [isSliderHovered, setIsSliderHovered] = useState(false);
    const sliderRef = useRef(null);

    const components = [Anticimex, Galp, Nos, Ericsson];

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        afterChange: current => setCurrentPage(current),
        nextArrow: isSliderHovered && <NextArrow />,
        prevArrow: isSliderHovered && <PrevArrow />,
    };

    const changePage = page => {
        setCurrentPage(page);
        sliderRef.current.slickGoTo(page);
    };

    return (
        <div className={'nimber-flex-center nimber-study-block'}>
            <div
                className={'nimber-max'}
                onMouseEnter={() => setIsSliderHovered(true)}
                onMouseLeave={() => setIsSliderHovered(false)}
            >
                <Slider ref={sliderRef} {...settings}>
                    {components.map((Component, index) => (
                        <div key={index}>
                            <div className={'slide-content-wrapper'}>
                                <Component />
                            </div>
                        </div>
                    ))}
                </Slider>
                <Pagination totalPages={components.length} onPageChange={changePage} currentPage={currentPage + 1}></Pagination>
            </div>
        </div>
    )
}

export default CaseStudy;