import '../styles/Footer.css'
import '../styles/Typography.css'
import '../styles/Button.css'
import React, { useState, useEffect } from 'react';

function Footer() {
    const [fixedFooterHeight, setFixedFooterHeight] = useState(0);

    // Function to update fixedFooterHeight based on the fixed footer's height
    const updateFooterHeight = () => {
        const fixedFooterElement = document.querySelector('.fixed-footer-container');
        if (fixedFooterElement) {
            setFixedFooterHeight(fixedFooterElement.clientHeight + 24);
        }
    };

    // Use useEffect with an empty dependency array to run after initial render
    useEffect(() => {
        updateFooterHeight(); // Call the function to set the initial height

        // Add event listener for window resize events
        const handleResize = () => {
            updateFooterHeight();
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll',handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll',handleResize);
        };
    }, []); // Empty dependency array means this effect runs once after the initial render


    return (
        <div className={'nimber-top-footer nimber-neutral-neutral-50 nimber-t-copyright'} style={{ paddingBottom: `${fixedFooterHeight}px` }}>
            ©2023 Copyright: Nimber
        </div>
    );
}

export default Footer;