import '../../styles/CaseStudy.css';
import '../../styles/Typography.css';
import '../../styles/Tag.css';
import '../../styles/Global.css';

function Galp() {
    const imageTechnologyUrls = [
        'images/technologies/Confluence.png',
        'images/technologies/drawio.png',
        'images/technologies/miro.png',
    ];

    return (
        <div className={'nimber-study-block'}>
            <div>
                <div>
                    <h3 className={'nimber-tag'}>CASE STUDY</h3>
                    <h1 className={'nimber-main-title nimber-foundation-neutral-500'}>Architecture Solution</h1>
                    <p className={'nimber-secondary-text nimber-foundation-neutral-500'}>The Enterprise Architecture & Technology’s (EA&T) mission is to enhance Galp's value by identifying new technology and optimizing IT assets. Our collaboration with business and IT departments defines quality standards and guidelines for Galp's digital transformation, making them a strategic partner for operational and business excellence.</p>
                </div>
                <div>
                    <h3 className={'nimber-t-study-info'}>Client</h3>
                    <img src={'images/clients/normal/Galp.png'} alt={'Galp logo'} className={'nimber-g-margin-top-16 nimber-g-margin-bottom-40'} />
                    <h3 className={'nimber-t-study-info'}>Main Technologies</h3>
                    <div className={'nimber-case-techs nimber-g-margin-top-16'}>
                        {imageTechnologyUrls.map((imageUrl, index) => (
                            <div>
                                <img key={index} src={imageUrl} alt={`a ${index}`} className="gallery-image" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Galp