import '../../styles/CaseStudy.css';
import '../../styles/Typography.css';
import '../../styles/Tag.css';
import '../../styles/Global.css';

function Anticimex() {
    const imageTechnologyUrls = [
        'images/technologies/Figma.png',
        'images/technologies/Supabase.png',
        'images/technologies/PostgreSQL.png',
        'images/technologies/aws.png',
        'images/technologies/React.png',
        'images/technologies/Next.png',
        'images/technologies/typeorm.png'
    ];

    return (
        <div className={'nimber-study-block'}>
            <div className={'nimber-study-left'}>
                <div>
                    <h3 className={'nimber-tag'}>CASE STUDY</h3>
                    <h1 className={'nimber-main-title nimber-foundation-neutral-500'}>Transforming and<br></br>creating a new<br></br>platform for<br></br>Anticimex</h1>
                    <p className={'nimber-secondary-text nimber-foundation-neutral-500'}>We solved this seven-headed beast. With an incredible team, fully dedicated, with visionary thinking and creative solutions. Design and development of a new platform for Anticimex Germany with different login profiles (clients, technicians and administrators using data base and CRM connection).</p>
                </div>
                <div>
                    <h3 className={'nimber-t-study-info'}>Client</h3>
                    <img src={'images/clients/normal/Anticimex.png'} alt={'Anticimex logo'} className={'nimber-g-margin-top-16 nimber-g-margin-bottom-40'} />
                    <h3 className={'nimber-t-study-info'}>Main Technologies</h3>
                    <div className={'nimber-case-techs nimber-g-margin-top-16'}>
                        {imageTechnologyUrls.map((imageUrl, index) => (
                            <div>
                                <img key={index} src={imageUrl} alt={`a ${index}`} className="gallery-image" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={'nimber-study-right'}>
                <img src={'images/product/ax-product.png'} alt={'Anticimex Product'}/>
            </div>
        </div>
    )
}

export default Anticimex