import '../styles/Cookies.css'

function Cookies() {
    function setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    function getCookie(name) {
        var nameEQ = name + "=";
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1, cookie.length);
            }
            if (cookie.indexOf(nameEQ) === 0) {
                return cookie.substring(nameEQ.length, cookie.length);
            }
        }
        return null;
    }

    function acceptCookies() {
        setCookie('cookieConsent', 'accepted', 365);
        document.getElementById('cookieConsentBanner').style.display = 'none';
    }

    function declineCookies() {
        setCookie('cookieConsent', 'declined', 365);
        document.getElementById('cookieConsentBanner').style.display = 'none';
    }

    function closeCookies() {
        document.getElementById('cookieConsentBanner').style.display = 'none';
    }

    window.onload = function() {
        var cookieConsent = getCookie('cookieConsent');
        if (cookieConsent !== null) {
            document.getElementById('cookieConsentBanner').style.display = 'none';
        }
    };

    const handleTermsLinkClick = () => {
        fetch('/files/policies.pdf')
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'terms.pdf';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            });
    };

    return (
        <div className={'cookie-container'} id={'cookieConsentBanner'}>
            <div className={'cookie-centering nimber-max'}>
                <div className={'cookie-img-wrapper'}>
                    <img src={'images/Cookies.png'} alt={'Cookies'} />
                    <div className={'mobile-close'}>
                        <img src={'images/Close.png'} alt={'Close'} onClick={closeCookies} style={{ cursor: "pointer"}}/>
                    </div>
                </div>
                <div className={'center-wrapper'}>
                    <div>
                        <h3 className={'cookie-main-text-color cookie-terms-header'}>Our website use cookies.</h3>
                        <p  className={'cookie-main-text-color cookie-sub-terms'}>Everyone aims to present their finest self – and so do we.</p>
                        <p  className={'cookie-main-text-color cookie-sub-terms'}>That's why we use cookies to ensure an enhanced experience for you.<span onClick={handleTermsLinkClick} className="cookie-sub-terms cookie-terms-color"> More Details.</span></p>
                    </div>
                    <div className={'button-wrapper'}>
                        <button type="submit" className={'nimber-full-button'} onClick={acceptCookies}>ACCEPT COOKIES</button>
                        <button type="submit" className={'nimber-semi-button'} onClick={declineCookies}>DECLINE ALL</button>
                    </div>
                </div>
                <div className={'desktop-close'}>
                    <img src={'images/Close.png'} alt={'Close'} onClick={closeCookies} style={{ cursor: "pointer"}}/>
                </div>
            </div>
        </div>
    );

}

export default Cookies
