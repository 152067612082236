import '../../styles/CaseStudy.css';
import '../../styles/Typography.css';
import '../../styles/Tag.css';
import '../../styles/Global.css';

function Nos() {
    const imageTechnologyUrls = [
        'images/technologies/catalyst.png',
        'images/technologies/dbix.png',
        'images/technologies/mysql.png',
        'images/technologies/perl.png',
    ];

    return (
        <div className={'nimber-study-block'}>
            <div>
                <div>
                    <h3 className={'nimber-tag'}>CASE STUDY</h3>
                    <h1 className={'nimber-main-title nimber-foundation-neutral-500'}>Data Center Portal</h1>
                    <p className={'nimber-secondary-text nimber-foundation-neutral-500'}>The main objective is to update the portal with the latest version of Catalyst and Perl language, create new endpoints, improve application performance, and resolve any bottlenecks within the project at the application, server, and database level.</p>
                </div>
                <div>
                    <h3 className={'nimber-t-study-info'}>Client</h3>
                    <img src={'images/clients/normal/nos.png'} alt={'Nos logo'} className={'nimber-g-margin-top-16 nimber-g-margin-bottom-40'} />
                    <h3 className={'nimber-t-study-info'}>Main Technologies</h3>
                    <div className={'nimber-case-techs nimber-g-margin-top-16'}>
                        {imageTechnologyUrls.map((imageUrl, index) => (
                            <div>
                                <img key={index} src={imageUrl} alt={`a ${index}`} className="gallery-image" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Nos